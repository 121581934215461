import React, { useState, createContext, useContext, ReactNode } from 'react'

interface DarkModeContextData {
  darkMode: boolean
  setDarkMode: (value: boolean) => void
}

const DarkModeContext = createContext<DarkModeContextData>({
  darkMode: false,
  setDarkMode: () => {},
})

export function useDarkMode() {
  return useContext(DarkModeContext)
}

function DarkModeProvider({ children }: { children: ReactNode }) {
  const [darkMode, setDarkMode] = useState(true)

  return <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>{children}</DarkModeContext.Provider>
}

export default DarkModeProvider
