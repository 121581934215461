import React, { ReactNode, createContext, useContext } from 'react'
import EmptyProfilePictureProvider from './EmptyProfilePictureContextProvider'
import { LoadingContextProvider } from './LoadingContextProvider'
import LoggedInUserProfileDataProvider from './LoggedInUserProfileDataContextProvider'
import DarkModeProvider from './DarkModeContextPovider'

const AppContext = createContext('insert default')

export const useAppContextHook = () => {
  return useContext(AppContext)
}

interface AppContextProviderProps {
  children: ReactNode
}

function AppContextProvider({ children }: AppContextProviderProps) {
  return (
    <DarkModeProvider>
      <LoggedInUserProfileDataProvider>
        <LoadingContextProvider>
          <EmptyProfilePictureProvider>{children}</EmptyProfilePictureProvider>
        </LoadingContextProvider>
      </LoggedInUserProfileDataProvider>
    </DarkModeProvider>
  )
}

export default AppContextProvider
